<template>
  <div>
    <div class="locale">
      <h2><a-button @click="$router.back()" shape="circle" icon="arrow-left"/>修改密码</h2>
      <ol>
        <li><router-link to="/">后台</router-link></li>
        <li><a href="javascript:;">系统</a></li>
        <li><a href="javascript:;">人员</a></li>
        <li>修改密码</li>
      </ol>
    </div>

        <a-form-model ref="form" :rules="form.rules" :model="form.data" v-bind="form.layout" @submit="onSubmit" @submit.native.prevent>
          <a-card title="人员信息">

            <a-form-model-item prop="id" label="编号">
              <a-input type="text" v-model="item.id" disabled />
            </a-form-model-item>
            <a-form-model-item prop="id" label="名字">
              <a-input type="text" v-model="item.name" disabled />
            </a-form-model-item>

            <a-form-model-item prop="password" label="新密码">
              <a-input type="password" placeholder="请输入新密码" v-model="form.data.password" />
            </a-form-model-item>

            <a-form-model-item prop="confirm" label="确认密码">
              <a-input type="password" placeholder="请再次输入新密码" v-model="form.data.confirm" />
            </a-form-model-item>

          </a-card>

          <div class="submit">
            <a-button type="primary" html-type="submit">确认修改</a-button>
          </div>

        </a-form-model>
  </div>
</template>

<script>
  export default {
    name: 'BlackCreates',
    data () {
      return {
        item: { organ: {} },
        form: {
          layout: {
            labelCol: { span: 6 },
            wrapperCol: { span: 10 },
          },
          rules: {
            password: [{ required: true, message: '请输入新密码' }],
            confirm: [{ required: true, message: '请再次输入新密码' }],
          },
          
          data: {
            password: '', confirm: ''
          }
        }
      }
    },
    mounted() {
      let id = this.$route.params.id;
      this.$get('/sys/staff/password/'+id).then( res => {
        let ajax = res.data;
        if (ajax.code == 0) {
          this.item = ajax.data.item;
        }
      })
    },

    methods: {

      onSubmit(){
        this.$refs['form'].validate(valid => {
          if (valid) {
            this.change();
          }
        });
      },
      change (){
        if (this.form.data.password != this.form.data.confirm) {
          this.$notify('两次密码输入不一致！')
          return false;
        }

      let id = this.$route.params.id;
        this.$post('/sys/staff/password/' + id, this.form.data).then(res => {
          let ajax = res.data;
          if (ajax.code == 0) {
            setTimeout(()=>{
              this.$router.go(-1);
            }, 500);

          }
        })

      }
    }
  }
</script>
<style lang="less" scoped>
</style>
